/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui";
import { darken, lighten } from "@theme-ui/color";
//import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const EventsGrid = ({ events, type = false }) => {
  return (
    <Grid sx={styles} gap={3} columns={[1, 2, 4]}>
      <Box key={`event-x`} className="eventTitle">
        <h2>{type && <span>{type} </span>}Events</h2>
      </Box>
      {events.map((item, index) => {
        const image = getImage(item.featuredImage);
        let date;
        const dateTimeFormat = new Intl.DateTimeFormat("en", {
          day: "2-digit",
          month: "short",
        });
        //if a long running event
        if (item.regularEvent) {
          date = item.regularEventDay;
        } else {
          date = new Date(item.date);
          date = dateTimeFormat.format(date);
        }

        console.log("date", date);
        return (
          <Link to={`/events/${item.slug}`}>
            <Box
              key={`event-${index}`}
              className={`eventItem ${type ? type : ""}`}
            >
              <GatsbyImage
                image={image}
                alt={item.featuredImage.alt}
                backgroundColor={`#ccc`}
              />
              <Box className="eventDetails">
                <h3>{item.title}</h3>
                <p>{date}</p>
              </Box>
            </Box>
          </Link>
        );
      })}
      {!type && (
        <Box className="moreButton">
          <Link to="/events">More Events</Link>
        </Box>
      )}
    </Grid>
  );
};

export default EventsGrid;

const styles = {
  mx: [4],
  my: [3],
  "& .eventTitle": {
    border: (theme) => `2px solid ${theme.colors.house3}`,
    height: ["150px", "300px", "300px"],
    display: "grid",
    gridArea: "1/1",
    placeItems: "center",
    h2: {
      fontSize: [4, 5],
      color: "house3",
      px: 2,
      textAlign: "center",
    },
  },
  "& > a": {
    textDecoration: "none",

    "& .eventItem": {
      height: ["300px"],
      display: "grid",
      "& .gatsby-image-wrapper": {
        gridArea: "1/1",
        transition: "all 0.3s ease-in-out",
      },
      "& .eventDetails": {
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        alignItems: "end",
        display: "grid",
        color: "white",
        h3: {
          fontSize: [3, 4],
          textAlign: "center",
          bg: "rgba(0, 0, 0, 0.4)",
          p: 2,
          mx: 3,
          my: 0,
          border: "1px solid white",
          transition: "all 0.3s ease-in-out",
        },
        p: {
          bg: "house3",
          px: 2,
          m: "8px auto",
          transition: "background 0.3s ease-in-out",
        },
      },
      "&.Past": {
        "& .gatsby-image-wrapper": {
          filter: "grayscale(1)",
        },
      },
    },
    "&:hover, &:focus-within": {
      //outline: (theme) => `2px solid ${theme.colors.house3}`,
      //outlineOffset: "-4px",
      "& .eventDetails": {
        p: {
          background: darken("house3", 0.1),
        },
        h3: {
          background: "rgba(240, 102, 145, 1)", //house3
        },
      },
      "& .gatsby-image-wrapper": {
        filter: "brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(2)",
      },
    },
  },

  "& .moreButton": {
    display: "flex",
    alignItems: "center",
    a: {
      bg: "house3",
      color: "white",
      textDecoration: "none",
      px: 2,
      py: 1,
      transition: "background 0.3s ease-in-out",
      "&:hover, &:focus": {
        background: darken("house3", 0.1),
      },
    },
  },
};
